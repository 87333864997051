import React, { Fragment } from "react";
import Img from "gatsby-image";
import PageLayout from "../../layouts/page-layout";
import Spacing from "../../components/spacing";
import { graphql } from "gatsby";

const Page = ({ data }) => {
  const imagesByName = data.allFile.edges.reduce(
    (allObj, each) => ({
      ...allObj,
      [each.node.base]: each.node.childImageSharp,
    }),
    {}
  );
  return (
    <PageLayout
      data={data}
      title="Channel surfing for Netflix"
      description="Felix - Chrome Extension available free from Google Chrome Web Store"
    >
      <p style={{ fontSize: "40px" }}>
        NetFelix extension documentation and demo has moved to{" "}
        <a href="https://foundingstack.com/felix">
          FoundingStack page about NetFelix
        </a>
      </p>
    </PageLayout>
  );
};

export default Page;

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/nf" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    profile: profileYaml {
      ...ProfileFragment
    }
    site {
      siteMetadata {
        title
        showThemeLogo
      }
    }
  }
`;
